import React from 'react';
import { Blank } from 'grommet-icons';

const IconSprayBottle = (props) => (
  <Blank viewBox="0 0 40 40" {...props}>
    <path
      d="M22.45,4.4451H9.85l-2.8,2.8v4.2H9.8664v4.7982H7.75v21.7h14v-7.35l-4.9-14.35h-1.4V11.4451h2.333l1.867,5.6h2.1l-1.8667-5.6H22.45v-1.4h2.8V5.8694l-2.8-.0162ZM15.8485,17.6433,20.35,30.8257v5.7175H9.15v-18.9h6.6985Zm-4.5821-1.4V11.4451H14.05v4.7982ZM21.05,10.0452H8.45v-2.22l1.98-1.98H21.05Zm1.4069-2.7918,1.3931.0079V8.6451H22.4568Zm4.8384-.71L26.3608,5.5,30.204,2.0567l.9345,1.0428Zm5.6547.7205v1.4H27.3418v-1.4ZM27.1251,9.5943l3.9081,3.5038-.9344,1.0425-3.9081-3.5038Z"
      fill="#000"
    />
  </Blank>
);

export default IconSprayBottle;
